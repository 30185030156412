import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import {Row, Col, Image} from "react-bootstrap"
import aboutPic1 from "../images/aboutus-pic1.jpg"
import aboutPic2 from "../images/aboutus-pic2.jpg"
import manuel from "../images/manuel.jpg"
import danny from "../images/danny.jpg"

import{graphql} from "gatsby"
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

const About = () => {
  const {t} = useTranslation();
  return(
    <>
      <HeardTopWrapper title={t('aboutUS')}/>
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('aboutUS')}</title>
        </Helmet>
        <PageContainer className="about-us">
          <Row className="about-us-mission">
            <Col className="about-us-mission-left">
              <Image src={aboutPic1}/>
              <h2>{t('ourMission')}</h2>
            </Col>
            <Col className="about-us-mission-right">
              <Image src={aboutPic2}/>
              <p>{t('aboutUSDes')}</p>
            </Col>
          </Row>
          <Row className="about-us-manuel">
            <Col xs={12} md={7}>
              <h2>{t('aboutChef')}</h2>
              <p>{t('aboutChefDes1')}</p>
              <p> {t('aboutChefDes2')}</p>
              <p> {t('aboutChefDes3')}</p>
              <p> {t('aboutChefDes4')}</p>
              <p> {t('aboutChefDes5')}</p>
              <p> {t('aboutChefDes6')}</p>
              <p> {t('aboutChefDes7')}</p>
              
            </Col>
            <Col xs={12} md={5}>
              <div className="author-name">
                <Image src={manuel}/>
              </div>
            </Col>
          </Row>
          <Row className="about-us-manuel">
            <Col xs={12} md={5}>
              <div className="author-name">
                <Image src={danny}/>
              </div>
            </Col>
            <Col xs={12} md={7}>
              <h2>{t('aboutDanny')}</h2>
              <p>{t('aboutDannyDes1')}</p>
              <p> {t('aboutDannyDes2')}</p>
              <p> {t('aboutDannyDes3')}</p>
              <p> {t('aboutDannyDes4')}</p>
              
            </Col>
          </Row>
        </PageContainer>
      </Layout>
    </>
  )
}
export default About
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;